<template>
  <v-dialog v-model="dialog" width="800" persistent>
    <v-card class="rounded-lg pl-10 pr-3 pb-6" width="800">
      <v-card-title>
        <span style="color: DarkCyan" class="text-h5">Players</span>

        <v-spacer></v-spacer>

        <v-btn color="white" text @click="selectplayersDialog = false">
          <v-icon color="#5E6278"> mdi-close </v-icon>
        </v-btn>
      </v-card-title>

      <v-divider class="mb-5"></v-divider>

      <v-row>
        <v-col class="mr-3" cols="auto"
          ><span style="color: DarkCyan"
            >Busque um player ou selecione itens na árvore:</span
          ></v-col
        >
      </v-row>

      <v-row>
        <v-col class="mt-2 mr-3" cols="auto"
          ><span>Busca de players:</span></v-col
        >
        <v-col class="mb-4" cols="3">
          <v-text-field
            clearable
            rounded
            filled
            hide-details=""
            dense
            background-color="#F5F8FA"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-treeview
            selectable
            item-disabled="locked"
            :items="items"
          ></v-treeview>
        </v-col>
      </v-row>

      <v-divider class="pb-2"></v-divider>

      <v-row class="mt-2">
        <v-spacer></v-spacer>
        <v-col cols="2">
          <v-btn
            :to="{ name: 'PlayersRss' }"
            height="35px"
            tile
            class="rounded white--text"
            small
            color="#3DA539"
          >
            Fechar
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "SelectPlayersRss",
  data() {
    return {
      form: {
        type: "customer",
      },
      dialog: true,
      email: "",
      items: [
        {
          id: 1,
          name: "Clinica Sim",
          children: [
            { id: 1, name: "BOA VISTA - PE - 1" },
            { id: 1, name: "CASA AMARELA - PE - 1" },
            { id: 1, name: "CENTRO - CE" },
            { id: 1, name: "FREI CIRILO - CE" },
            { id: 1, name: "FÁTIMA - CE" },
            { id: 1, name: "ITAPERI - CE" },
            { id: 1, name: "MARAPONGA - CE" },
            { id: 1, name: "MESSEJANA" },
            { id: 1, name: "MONTESE" },
            { id: 1, name: "NORTH SHOPPING FORTALEZA" },
            { id: 1, name: "PARQUE SHOPPING BÉLEM" },
            { id: 1, name: "PRAZERES - PE - 1" },
            { id: 1, name: "PÁTIO BELÉM" },
            { id: 1, name: "RIO MAR KENNEDY - CE" },
            { id: 1, name: "RIOMAR - RECIFE" },
            { id: 1, name: "RIOMAR FORTALEZA" },
            { id: 1, name: "SHOPPING TACARUNA" },
            { id: 1, name: "UNIDADE GUARARAPES" },
            { id: 1, name: "VIA SUL SHOPPING - CE - TERMIANAL 2" },
            { id: 1, name: "MARACANAÚ - CLÍNICA SIM" },
            { id: 1, name: "Via Sul Shopping-2 - CLÍNICA SIM" },
            { id: 1, name: "FÁTIMA 2 - CLÍNICA SIM" },
          ],
        },
        {
          id: 2,
          name: "Group DEMO",
          children: [
            { id: 2, name: "Saúde TV - Salvador" },
            { id: 2, name: "LPC - BARRA" },
            { id: 2, name: "LPC - IMBUÍ" },
            { id: 2, name: "LPC - LUCAIA" },
            { id: 2, name: "LPC - ITAPUÃ" },
            { id: 2, name: "LPC - PITUBA" },
            { id: 2, name: "CLÍNICA SER" },
            { id: 2, name: "OMNIMAGEM - 1" },
            { id: 2, name: "OMNIMAGEM - 2" },
            { id: 2, name: "OMNIMAGEM - 3" },
            { id: 2, name: "OMNIMAGEM - 4" },
            { id: 2, name: "OMNIMAGEM - 5" },
            { id: 2, name: "OMNIMAGEM - 6" },
            { id: 2, name: "OMNIMAGEM - RIOMAR" },
            { id: 2, name: "OMNIMAGEM - 24 de Maio - Recepção Principal" },
            { id: 2, name: "OMNIMAGEM MAMOGRAFIA" },
            { id: 2, name: "MAX CLINICA 1" },
            { id: 2, name: "MAX CLINICA 2" },
            { id: 2, name: "RADIOGÊNESIS UNIDADE MEDICAL GÊNESIS 1" },
            { id: 2, name: "OMNIMAGEM - 24 DE MAIO" },
            { id: 2, name: "RADIOGÊNESIS HOSPITAL GÊNESIS 1" },
            { id: 2, name: "RADIOGÊNESIS BS TOWER" },
            { id: 2, name: "CLEMENTINO FRAGA JOVITA FEITOSA" },
            { id: 2, name: "CLEMENTINO FRAGA - 13 DE MAIO" },
            { id: 2, name: "CLEMENTINO FRAGA 13 DE MAIO - 2" },
            { id: 2, name: "CLEMENTINO FRAGA CENTRAL" },
            { id: 2, name: "CLEMENTINO FRAGA PADRE ANTÔNIO TOMAZ" },
            { id: 2, name: "HOSPITAL SÃO CARLOS 1" },
            { id: 2, name: "HOSPITAL SÃO CARLOS 2" },
            { id: 2, name: "HOSPITAL SÃO CARLOS 3" },
            { id: 2, name: "HOSPITAL SÃO RAIMUNDO" },
            { id: 2, name: "OMNIMAGEM - 24 DE MAIO - 1" },
            { id: 2, name: "HOSPITAL SÃO RAIMUNDO 3" },
            { id: 2, name: "OMNIMAGEM - 24 DE MAIO - 2" },
            { id: 2, name: "HOSPITAL PRONTOCÁRDIO" },
            { id: 2, name: "TERMINAL TESTE NOGUEIRA" },
            { id: 2, name: "TACARUNA - TERMINAL 2" },
            { id: 2, name: "HAMMER - 1 - BARRA" },
            { id: 2, name: "HAMMER - 2 - BARRA" },
            { id: 2, name: "HAMMER - 4 - PATAMARES" },
            { id: 2, name: "HAMMER - 1 - PITUBA" },
            { id: 2, name: "HAMMER - 2 PITUBA" },
            { id: 2, name: "HAMMER - 3 - PITUBA" },
            { id: 2, name: "HAMMER - 4 - PITUBA" },
            { id: 2, name: "HAMMER - PITUBA - 5" },
            { id: 2, name: "HAMMER - STELLA 2 - 1" },
            { id: 2, name: "HAMMER - STELLA 2 - 2" },
            { id: 2, name: "HAMMER - STELLA 2 - 3" },
            { id: 2, name: "HAMMER - STELLA 2 - 4" },
            { id: 2, name: "HAMMER - 1 - PATAMARES" },
            { id: 2, name: "HAMMER - 2 - PATAMARES" },
            { id: 2, name: "HAMMER - 3 - PATAMERES" },
            { id: 2, name: "HAMMER - STELLA 1 - 1" },
            { id: 2, name: "HAMMER - STELLA 1 - 2" },
            { id: 2, name: "HAMMER - STELLA 1 - 3" },
            { id: 2, name: "TESTE - SAÚDE TV" },
          ],
        },
        {
          id: 3,
          name: "Hospital São Camilo",
          children: [
            { id: 3, name: "HOSPITAL SÃO CAMILO - 1" },
            { id: 3, name: "HOSPITAL SÃO CAMILO - 2" },
            { id: 3, name: "HOSPITAL SÃO CAMILO - 3" },
            { id: 3, name: "HOSPITAL SÃO CAMILO - 4" },
            { id: 3, name: "HOSPITAL SÃO CAMILO - 5" },
            { id: 3, name: "HOSPITAL SÃO CAMILO - 6" },
            { id: 3, name: "HOSPITAL SÃO CAMILO - 7" },
            { id: 3, name: "HOSPITAL SÃO CAMILO - 8" },
            { id: 3, name: "HOSPITAL SÃO CAMILO - 9" },
            { id: 3, name: "HOSPITAL SÃO CAMILO - 10" },
            { id: 3, name: "HOSPITAL SÃO CAMILO - 11 VIDEOWALL" },
          ],
        },
        {
          id: 4,
          name: "NOVA LAPA",
          children: [
            { id: 4, name: "LAPA INTERATIVA - TERMINAL 1" },
            { id: 4, name: "NOVA LAPA - LED 1" },
            { id: 4, name: "NOVA LAPA - LED 2" },
            { id: 4, name: "NOVA LAPA - LED 3" },
          ],
        },
        {
          id: 5,
          name: "RECIFE",
          children: [
            { id: 5, name: "CLÍNICA DE SAÚDE DO CABO - RECIFE" },
            { id: 5, name: "CLÍNICA GOPE - RECIFE" },
            { id: 5, name: "CLÍNICA JULIANA MACHADO - RECIFE" },
            { id: 5, name: "CONSULTÓRIO MÉDICO DO POVO - RECIFE/PE" },
            { id: 5, name: "CLÍNICA LÍDER - ALL LIFE - RECIFE" },
            { id: 5, name: "CLÍNICA MEU DOUTOR - RECIFE" },
            { id: 5, name: "CONSULTÓRIO MÉDICO DO IBURA -RECIFE" },
            { id: 5, name: "CONSULTÓRIO MÉDICO DO POVO - RECIFE - 2" },
            { id: 5, name: "CENTRO DA VISÃO 01 - RECIFE" },
            { id: 5, name: "CENTRO DA VISÃO 02 - RECIFE" },
            { id: 5, name: "CONSULTÓRIOS MORENO" },
            { id: 5, name: "CLÍNICA IOP 01 - RECIFE" },
            { id: 5, name: "CLÍNICA SENA SAÚDE - TERMINAL 1 - RECIFE" },
            { id: 5, name: "CLÍNICA SENA SAÚDE - TERMINAL 2 -RECIFE" },
            { id: 5, name: "ULTRAMED - RECIFE" },
            { id: 5, name: "OTORRINO FRONTEIRAS - RECIFE" },
            { id: 5, name: "STUDIO DE PILATES - RECIFE" },
            { id: 5, name: "LUCILO MARANHÃO - 1" },
            { id: 5, name: "LUCILO MARANHÃO - 2" },
            { id: 5, name: "LUCILO MARANHÃO - 3" },
            { id: 5, name: "LUCILO MARANHÃO - 4" },
            { id: 5, name: "LUCILO MARANHÃO - 5" },
            { id: 5, name: "LUCILO MARANHÃO - 6" },
            { id: 5, name: "LUCILO MARANHÃO - 7" },
            { id: 5, name: "LUCILO MARANHÃO - 8" },
            { id: 5, name: "LUCILO MARANHÃO - 9" },
            { id: 5, name: "LUCILO MARANHÃO - 10" },
            { id: 5, name: "NOVA LAPA - LED 3" },
            { id: 5, name: "NOVA LAPA - LED 3" },
            { id: 5, name: "NOVA LAPA - LED 3" },
            { id: 5, name: "NOVA LAPA - LED 3" },
            { id: 5, name: "NOVA LAPA - LED 3" },
            { id: 5, name: "NOVA LAPA - LED 3" },
            { id: 5, name: "NOVA LAPA - LED 3" },
            { id: 5, name: "NOVA LAPA - LED 3" },
            { id: 5, name: "NOVA LAPA - LED 3" },
            { id: 5, name: "NOVA LAPA - LED 3" },
            { id: 5, name: "NOVA LAPA - LED 3" },
            { id: 5, name: "NOVA LAPA - LED 3" },
            { id: 5, name: "NOVA LAPA - LED 3" },
            { id: 5, name: "NOVA LAPA - LED 3" },
            { id: 5, name: "NOVA LAPA - LED 3" },
            { id: 5, name: "NOVA LAPA - LED 3" },
            { id: 5, name: "NOVA LAPA - LED 3" },
            { id: 5, name: "NOVA LAPA - LED 3" },
            { id: 5, name: "NOVA LAPA - LED 3" },
            { id: 5, name: "NOVA LAPA - LED 3" },
            { id: 5, name: "NOVA LAPA - LED 3" },
            { id: 5, name: "NOVA LAPA - LED 3" },
            { id: 5, name: "NOVA LAPA - LED 3" },
            { id: 5, name: "NOVA LAPA - LED 3" },
            { id: 5, name: "NOVA LAPA - LED 3" },
            { id: 5, name: "NOVA LAPA - LED 3" },
            { id: 5, name: "NOVA LAPA - LED 3" },
            { id: 5, name: "NOVA LAPA - LED 3" },
          ],
        },
      ],
      rules: {
        required: (value) => !!value || "Obrigatório.",
      },
    };
  },
};
</script>
