<template>
  <v-dialog v-model="dialog" width="800" persistent>
    <v-card class="rounded-lg pl-10 pr-3 pb-6" width="800">
      <v-card-title>
        <span style="color: DarkCyan" class="text-h5"></span>

        <v-spacer></v-spacer>

        <v-btn color="white" text :to="{ name: 'Rss' }">
          <v-icon color="#5E6278"> mdi-close </v-icon>
        </v-btn>
      </v-card-title>

      <v-divider class="mb-5"></v-divider>

      <v-row>
        <v-btn
          @click="selectplayersDialog = true"
          small
          class="mt-2"
          color="primary"
          dark
        >
          <v-icon dark> mdi-plus </v-icon>
        </v-btn>
        <v-col cols="10" class="ml-2 mb-4"
          ><span
            >Clique no botão para selecionar os players aos quais as fontes RSS
            serão associadas
          </span></v-col
        >
      </v-row>

      <v-dialog v-model="selectplayersDialog" persistent>
        <RssSelectPlayers />
      </v-dialog>

      <v-divider class="pb-2"></v-divider>
    </v-card>
  </v-dialog>
</template>

<script>
import RssSelectPlayers from "./_components/RssSelectPlayers";
export default {
  name: "PlayersRss",
  data() {
    return {
      form: {
        type: "customer",
      },
      selectplayersDialog: false,
      dialog: true,
      email: "",
      rules: {
        required: (value) => !!value || "Obrigatório.",
      },
    };
  },
  components: {
    RssSelectPlayers,
  },
};
</script>
